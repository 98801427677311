body, html {
    font-family: 'PT Sans', sans-serif;
    color: rgb(33, 37, 41);
    overflow-x: hidden;
}

.header-logo {
    max-width: 92%;
}

a {
    text-decoration: none;
    color: #0000e0;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

hr {
    border-top: 1px solid lightgrey;
    color: transparent;
}

h1 {
    /* font-weight: bold; */
    font-size: 26px;
    margin: 10px 0 20px;
}

h2 {
    /* font-weight: bold; */
    font-size: 26px;
    margin: 10px 0 20px;
}

.text-center {
    text-align: center;
}

.container {
    max-width: 1100px;
    padding: 10px;
    margin: auto;
}


/***
 * Header
 **/

.header-icons {
    padding: 30px 20px;
}

.header-icons i {
    margin-right: 4px;
}
.contact-info {
    text-align: right;
    color: white;
    float: right;
    padding-right: 20px;
}

.info-pane {
    background: #000;
    border-top: solid 3px rgb(106,127,16);
    border-bottom: solid 1px rgb(88,88,88);
    width: 100%;
    padding: 14px 0;
}

.info-pane a {
    color: #bebdbd;
}

.info-pane a:hover, .info-pane a:focus {
    color: #989797;
}

.Logo {
    background: #000;
    color: #FFF;
    border-bottom: solid 2px rgb(255,255,255);
}

.cat-header {
    background: rgb(106,127,16);
    border-bottom: solid 1px rgb(210,211,212);
    border-top: solid 1px rgb(210,211,212);
}

.mcecs-cat-logo {
    background: #000;
    color: #FFF;
    padding: 20px 0;
    padding-left: 20px;
}

.message-item .message-text p{
    margin-bottom: 0;
}


/* .status-link-center {
  width: 100%;
  text-align: center;
} */

.hidden {
    display: none !important;
}


/* Status Light */

.status-light {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    border-radius: 50%;
    position: relative;
    top: 2px;
    display: inline-block;
    transition:background 2s;
}


/* Media Queries */

@media screen and (max-width: 540px) {
    .mcecs-cat-logo {
        text-align: center;
        padding-left: 6px !important;
    }
    .contact-info {
        font-size: 14px;
    }
    .hidden-mobile {
        display: none;
    }
}
