#drop-area {
  border: 1px dashed #007bff;
  border-radius: 20px;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}
