
/*.fa-li {*/
/*    font-size: 30px;*/
/*}*/

/* small screens */
@media screen and (max-width: 1024px) {
    .fa-ul li {
        font-size: 15px;
    }

    #welcomeForm .form-group {
        width: 100%;
    }
}

/* medium and larger screens */
@media screen and (min-width: 1024px) {
    .fa-ul li {
        font-size: 20px;
    }

    #welcomeForm .form-group {
        width: 40%;
    }
}



